<template>
  <v-card outlined>
    <v-card-title>
      {{ currentRouteName }}

      <v-spacer></v-spacer>
      <v-btn depressed color="info" to="/supplier/manage"> add new supplier </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item">
          <v-card flat>
            <v-card-text>
              <v-data-table @click:row="conChildSupplierClick"
                v-if="tab == 0"
                key="tab_datatable_suppliers_1"
                :headers="headers"
                :items="distribuyeSuppliers"
              ></v-data-table>
              <v-container
                v-if="tab == 1 && currentUserType.key != 'tenant_user'"
              >
                <v-select
                  @change="onTenantChange"
                  hide-details=""
                  :items="tenants"
                  item-text="name"
                  item-value="id"
                  label="Select Tenant"
                  outlined
                ></v-select>
              </v-container>
              <v-data-table
                v-if="tab == 1"
                key="tab_datatable_suppliers_2"
                :headers="headersbyTenant"
                :items.sync="suppliersByTenant"
              ></v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: null,
      items: ["Suppliers Distribuye", "Suppliers Inventory"],
      headersbyTenant: [
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Name",
          value: "name",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Address",
          value: "address",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Supplier Email",
          value: "email",
        },
      ],
      headers: [
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Name",
          value: "entityName",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Address",
          value: "physicalAddress",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Supplier Email",
          value: "email",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "City",
          value: "physicalCity",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "suppliers",
      "tenants",
      "suppliersByTenant",
      "currentUserType",
      "currentTenant",
    ]),
    currentRouteName() {
      return this.$route.name;
    },
    distribuyeSuppliers() {
      const self = this;
      if (self.suppliers && !self.suppliers.distribuye) return [];
      return self.suppliers.distribuye;
    },
    inventorySuppliers() {
      const self = this;
      if (!self.suppliers.inventory) return [];
      return self.suppliers.inventory;
    },
  },
  methods: {
    ...mapActions(["getAllSuppliers", "getSuppliersByTenant", "getAllTenants"]),
    onTenantChange(val) {
      const self = this;
      self.getSuppliersByTenant(val);
    },
    conChildSupplierClick(item){
      const self = this;
      self.$router.push({path: `manage/${item._id}`});
    }
  },
  async created() {
    const self = this;
    await self.getAllSuppliers();
    await self.getAllTenants();
    if (self.currentUserType.key == "tenant_user") {
      self.getSuppliersByTenant(self.currentTenant.id);
    }
  },
};
</script>

<style>
</style>